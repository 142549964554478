const themeReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_BROWSER_DIMENSIONS":
      return {
        ...state,
        browserWidth: action.payload.browserWidth,
        browserHeight: action.payload.browserHeight,
        heroHeight: action.payload.heroHeight,
      }
    case "SET_LANG":
      return {
        ...state,
        lang: action.payload.lang,
      }

    case "SHOW_OVERLAY":
      return {
        ...state,
        overlayContent: action.payload.overlayContent,
      }

    case "HIDE_OVERLAY":
      return {
        ...state,
        overlayContent: null,
      }

    case "SPLASH_SHOWN":
      return {
        ...state,
        splashShown: true,
      }
    case "SET_ACTIVE_SECTION":
      return {
        ...state,
        activeSection: action.payload,
      }
    case "SET_ACTIVE_SUB_SECTION":
      return {
        ...state,
        activeSubSection: action.payload,
      }
    default:
      return state
  }
}
export default themeReducer
