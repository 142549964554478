import React, { useEffect, useReducer } from "react";
import ThemeContext from "../stores/ThemeContext";
import ThemeReducer from "../stores/ThemeReducer";
import "../sass/main.scss";

const Layout = props => {
  const isClient = typeof window === "object";

  const initialThemeState = {
    lang: "en",
    menuOpen: false,
    cfState: "",
    overlayContent: null,
    browserWidth: isClient ? window.innerWidth : undefined,
    browserHeight: isClient ? window.innerHeight : undefined,
    activeSection: 1,
    activeSubSection: 1
  };
  const [themeOpt, dispatch] = useReducer(ThemeReducer, initialThemeState);

  const appVariables = () => {
    document.documentElement.style.setProperty("--app-height", `${window.innerHeight}px`)
  }

  useEffect(() => {

    const handleResize = () => {
      dispatch({
        type: "UPDATE_BROWSER_DIMENSIONS",
        payload: {
          browserWidth: isClient ? window.innerWidth : undefined,
          browserHeight: isClient ? window.innerHeight : undefined
        }
      });
      appVariables()
    };

    const handleScrolling = () => {

      if (window.scrollY > localStorage.getItem('header_offset')) {
        document.body.classList.add("scrolling");
      } else {
        document.body.classList.remove("scrolling");
      }
    };

    window.addEventListener("scroll", handleScrolling);
    window.addEventListener("resize", handleResize);


    if (isClient) {
      appVariables()
      const isMac = navigator?.platform.toUpperCase().indexOf('MAC') >= 0;
      if (isMac) {
        document.body.classList.add("isMac");
      }
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      //window.removeEventListener("scroll", handleScrolling);
    };

  }, [isClient]);


  return (
    <ThemeContext.Provider value={{ themeOpt, dispatch }}>
      {props.children}
    </ThemeContext.Provider>
  );
};
export default Layout;
